import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./AddressFieldset.scss";
import InputFormik from "app/pages/.shared/form/InputFormik";
import SelectFormik from "app/pages/.shared/form/SelectFormik";
import injectCountries from "app/utils/intl/InjectCountries";
import {
	sendTagOnGoogleAutoCompleteError,
	sendTagOnGoogleAutocompleteSelect,
} from "app/utils/analytics";
import AddressAutocompleteFormik from "app/pages/.shared/form/AddressAutocompleteFormik";

class AddressFieldsetFormik extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loadingAddressDetail: false,
		};
		this.handleAddressSelection = this.handleAddressSelection.bind(this);
		this.handleBeforeAddressSelection = this.handleBeforeAddressSelection.bind(this);
	}

	handleBeforeAddressSelection() {
		this.setState({
			loadingAddressDetail: true,
		});
	}

	handleAddressSelection(place) {
		const { countries, setFieldValue } = this.props;

		this.setState(
			{
				loadingAddressDetail: false,
			},
			() => {
				const countryCode = countries.find(
					country => country.name === place.components.country
				);

				if (countryCode) {
					setFieldValue("country-name", countryCode.cca2);
				}
				setFieldValue("address-line1", place.name);
				setFieldValue("address-line2", undefined);
				setFieldValue("postal-code", place.components.postalCode);
				setFieldValue("address-level2", place.components.city);

				sendTagOnGoogleAutocompleteSelect(place.formattedAddress);
			}
		);
	}

	handleAutocompleteError(type, status) {
		sendTagOnGoogleAutoCompleteError(type, status);
	}

	render() {
		const { countries, isRequired, setFieldValue, shop } = this.props;
		const { loadingAdresseDetail } = this.state;

		const isGB = shop === "en-GB";

		return (
			<div className="address">
				<div className="address__line1 address__input address__item">
					<AddressAutocompleteFormik
						isRequired={isRequired}
						id="address-line1"
						name="address-line1"
						onError={this.handleAutocompleteError}
						onPlaceSelect={this.handleAddressSelection}
						onBeforePlaceSelect={this.handleBeforeAddressSelection}
						label={<FormattedMessage id="address.address1.label" />}
						setFieldValue={setFieldValue}
						data-cy="address-line1-input"
					/>
				</div>

				<div className="address__line2 address__input address__item">
					<InputFormik
						id="address-line2"
						name="address-line2"
						label={<FormattedMessage id="address.address2.label" />}
						data-cy="address-line2-input"
					/>
				</div>

				<div className="address__row">
					<div className="address__postcode address__input address__item">
						{isGB ? (
							<AddressAutocompleteFormik
								isRequired={isRequired}
								id="postal-code"
								name="postal-code"
								onError={this.handleAutocompleteError}
								onPlaceSelect={this.handleAddressSelection}
								onBeforePlaceSelect={this.handleBeforeAddressSelection}
								label={<FormattedMessage id="address.postCode.label" />}
								setFieldValue={setFieldValue}
								enableSuggestionWithPostCode={true}
								data-cy="postal-code-input"
							/>
						) : (
							<InputFormik
								id="postal-code"
								name="postal-code"
								isRequired={isRequired}
								loading={loadingAdresseDetail}
								label={<FormattedMessage id="address.postCode.label" />}
								maxLength="12"
								data-cy="postal-code-input"
							/>
						)}
					</div>
					<div className="address__city address__input address__item">
						<InputFormik
							id="address-level2"
							name="address-level2"
							isRequired={isRequired}
							loading={loadingAdresseDetail}
							label={<FormattedMessage id="address.city.label" />}
							maxLength="50"
							data-cy="city-input"
						/>
					</div>
					<div className="address__country address__select address__item">
						<SelectFormik
							id="country-name"
							name="country-name"
							isRequired={isRequired}
							loading={loadingAdresseDetail}
							label={<FormattedMessage id="address.country.label" />}
							data-cy="country-select-input"
						>
							<option />
							{countries.map(country => (
								<option key={country.cca2} value={country.cca2}>
									{country.name}
								</option>
							))}
						</SelectFormik>
					</div>
				</div>
			</div>
		);
	}
}

AddressFieldsetFormik.defaultProps = {
	countries: [],
	isRequired: false,
	shop: "",
};

AddressFieldsetFormik.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.object),
	shop: PropTypes.string,
	isRequired: PropTypes.bool,
	setFieldValue: PropTypes.func.isRequired,
};

export default injectCountries(AddressFieldsetFormik);
