import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CIVILITY } from "app/constants";
import SelectFormik from "app/pages/.shared/form/SelectFormik";
import InputFormik from "app/pages/.shared/form/InputFormik";
import "./IdentityFieldset.scss";

const IdentityFieldsetFormik = ({ isRequired }) => {
	return (
		<div className="identity">
			<div className="identity__gender identity__item identity__radio">
				<SelectFormik
					id="honorific-prefix"
					name="honorific-prefix"
					isRequired={isRequired}
					label={<FormattedMessage id="identity.gender.label" />}
					data-cy="gender-select-input"
				>
					<option />
					{[CIVILITY.MALE, CIVILITY.FEMALE].map(gender => (
						<FormattedMessage
							key={gender}
							id={`general.gender.${gender.toLowerCase()}`}
						>
							{message => (
								<option key={gender} value={gender}>
									{message}
								</option>
							)}
						</FormattedMessage>
					))}
				</SelectFormik>
			</div>

			<div className="identity__lastname identity__item identity__input">
				<InputFormik
					id="family-name"
					name="family-name"
					isRequired={isRequired}
					label={<FormattedMessage id="identity.lastname.label.full" />}
					maxLength="30"
					data-cy="family-name-input"
				/>
			</div>

			<div className="identity__firstname identity__item identity__input">
				<InputFormik
					id="given-name"
					name="given-name"
					isRequired={isRequired}
					label={<FormattedMessage id="identity.firstname.label" />}
					maxLength="30"
					data-cy="given-name-input"
				/>
			</div>
		</div>
	);
};

IdentityFieldsetFormik.propTypes = {
	isRequired: PropTypes.bool,
};

export default React.memo(IdentityFieldsetFormik);
