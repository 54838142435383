import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Input from "app/pages/.shared/form/Input";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import * as Sentry from "@sentry/react";

import "./PhoneIntl.scss";

const PhoneIntl = ({ id, name, field, isRequired, shop, label, ...restProps }) => {
	const [lang, country] = shop.split("-");
	const [locale, setLocale] = useState();

	const handleChange = useCallback(
		value => {
			field.onChange(value);
		},
		[field]
	);

	const handleFocus = useCallback(event => {
		field.onFocus(event);
	}, []);

	useEffect(() => {
		import(/* webpackChunkName: "phone-country-[request]" */
		`react-phone-number-input/locale/${lang}.json`)
			.then(locale => {
				setLocale(locale.default);
			})
			.catch(err => {
				Sentry.captureException(err);
			});
	}, [lang]);

	// pour la prop value, PhoneInput s'attend à avoir undefined et non une string vide pour bien fonctionner
	// or redux-form fournit une string vide si pas de valeur
	return (
		<PhoneInput
			id={id}
			name={name}
			className="phone-intl-input"
			displayInitialValueAsLocalNumber
			inputComponent={Input}
			defaultCountry={country}
			value={field.value === "" ? undefined : field.value}
			labels={locale}
			onChange={handleChange}
			onFocus={handleFocus}
			isRequired={isRequired}
			numberInputProps={{
				type: "tel",
				label: label,
				maxLength: "50",
				field,
				["data-cy"]: restProps["data-cy"],
			}}
		/>
	);
};

PhoneIntl.defaultProps = {
	shop: "",
	isRequired: false,
	field: {},
	label: <FormattedMessage id="phone.label" />,
};

PhoneIntl.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	field: PropTypes.object,
	isRequired: PropTypes.bool,
	shop: PropTypes.string,
	onBlur: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default React.memo(PhoneIntl);
