import React from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import range from "lodash/range";
import SelectFormik from "app/pages/.shared/form/SelectFormik";
import { PASSENGER_TYPE } from "app/constants";
import "./BirthdateInput.scss";

const BirthdateInputFormik = ({ passengerType }) => {
	const thisYear = new Date().getFullYear();

	let maxYear = thisYear - 12;
	let minYear = 1900;

	if (passengerType === PASSENGER_TYPE.CHILD) {
		// enfant entre 2 et 11 ans
		maxYear = thisYear - 3;
		minYear = thisYear - 12;
	} else if (passengerType === PASSENGER_TYPE.INFANT) {
		// enfant entre 0 et 2 ans
		maxYear = thisYear;
		minYear = thisYear - 3;
	}

	return (
		<div className="birthdate">
			<div className="birthdate__day birthdate__item birthdate__select">
				<SelectFormik
					id="birthdateDay"
					name="birthdateDay"
					label={<FormattedMessage id="profile.birthdate.day.label" />}
					data-cy="birthdate-day-select-input"
				>
					<option />
					{range(1, 32).map(day => (
						<option key={day} value={day}>
							{day}
						</option>
					))}
				</SelectFormik>
			</div>

			<div className="birthdate__month birthdate__item birthdate__select">
				<SelectFormik
					id="birthdateMonth"
					name="birthdateMonth"
					label={<FormattedMessage id="profile.birthdate.month.label" />}
					data-cy="birthdate-month-select-input"
				>
					<option />
					{range(1, 13).map(month => {
						/* utilisation d'un object Date pour fix erreur traduction dans firefox et safari */
						return (
							<FormattedDate
								key={month}
								value={new Date(thisYear, month - 1, 1)}
								month="long"
							>
								{monthLabel => <option value={month}>{monthLabel}</option>}
							</FormattedDate>
						);
					})}
				</SelectFormik>
			</div>

			<div className="birthdate__year birthdate__item birthdate__select">
				<SelectFormik
					id="birthdateYear"
					name="birthdateYear"
					label={<FormattedMessage id="profile.birthdate.year.label" />}
					data-cy="birthdate-year-select-input"
				>
					<option />
					{range(maxYear, minYear).map(year => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</SelectFormik>
			</div>
		</div>
	);
};

BirthdateInputFormik.defaultProps = {
	passengerType: PASSENGER_TYPE.ADULT,
};

BirthdateInputFormik.propTypes = {
	passengerType: PropTypes.oneOf([
		PASSENGER_TYPE.CHILD,
		PASSENGER_TYPE.INFANT,
		PASSENGER_TYPE.ADULT,
	]),
};

export default React.memo(BirthdateInputFormik);
