import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import injectCountries from "app/utils/intl/InjectCountries";
import SelectFormik from "app/pages/.shared/form/SelectFormik";
import "./Nationality.scss";

const NationalityFormik = ({ countries }) => {
	return (
		<div className="nationality">
			<SelectFormik
				id="nationality"
				name="nationality"
				label={<FormattedMessage id="nationality.label" />}
				data-cy="nationality-select-input"
			>
				<option />
				{countries.map(country => (
					<option key={country.cca2} value={country.cca2}>
						{country.name}
					</option>
				))}
			</SelectFormik>
		</div>
	);
};

NationalityFormik.defaultProps = {
	countries: [],
};

NationalityFormik.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.object), // from injectCountries HOC
};

export default injectCountries(NationalityFormik);
