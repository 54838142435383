import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import PhoneInput from "react-phone-number-input";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { FormattedMessage } from "react-intl";
import * as Sentry from "@sentry/react";
import "./PhoneIntlFormik.scss";

const PhoneIntlFormik = ({
	id,
	name,
	isRequired,
	shop,
	setFieldValue,
	setFieldTouched,
	label,
	...restProps
}) => {
	const [field] = useField(name);
	const [lang, country] = shop.split("-");
	const [locale, setLocale] = useState();

	const handleChange = useCallback(
		value => {
			setFieldTouched(name);
			setFieldValue(name, value);
		},
		[name]
	);

	useEffect(() => {
		import(/* webpackChunkName: "phone-country-[request]" */
		`react-phone-number-input/locale/${lang}.json`)
			.then(locale => {
				setLocale(locale.default);
			})
			.catch(err => {
				Sentry.captureException(err);
			});
	}, [lang]);

	return (
		<PhoneInput
			id={id}
			name={name}
			className="phone-intl-input"
			displayInitialValueAsLocalNumber
			inputComponent={InputFormik}
			defaultCountry={country}
			value={field.value}
			labels={locale}
			onChange={handleChange}
			isRequired={isRequired}
			numberInputProps={{
				type: "tel",
				label: label,
				maxLength: "50",
			}}
			{...restProps}
		/>
	);
};

PhoneIntlFormik.defaultProps = {
	label: <FormattedMessage id="phone.label" />,
};

PhoneIntlFormik.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
	shop: PropTypes.string,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default PhoneIntlFormik;
